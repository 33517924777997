import React from "react"
import Image from "gatsby-plugin-sanity-image"
import { BlockWrapper } from "../blockWrapper"

const ImageBlock = ({ blockConfig, image, layout }) => {
  return (
    <BlockWrapper {...blockConfig} className="px-gutter overflow-x-hidden">
      {image && <Image className="w-full h-auto" {...image} />}
    </BlockWrapper>
  );
}

export default ImageBlock
