import React, { useState, useEffect, useCallback } from "react"
import { useEmblaCarousel } from "embla-carousel/react"
import { setupWheelGestures } from "embla-carousel-wheel-gestures"

// Components
import ArrowL from '../../svg/arrow-l.svg';
import ArrowR from '../../svg/arrow-r.svg';
const StandardCarousel = ({ useArrows, useNav, heading, children, className }) => {
  const [scrollProgress, setScrollProgress] = useState(0);

  const [emblaRef, emblaApi] = useEmblaCarousel({ align: 'start', loop: false });

  const onScroll = useCallback(() => {
    if (!emblaApi) return;
    setScrollProgress(emblaApi.scrollProgress() * 100);
  }, [emblaApi]);

  useEffect(() => {
    if (emblaApi) {
      // Embla API is ready
      emblaApi.on('scroll', onScroll);
      setupWheelGestures(emblaApi);
    }
  }, [emblaApi, onScroll]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollTo = useCallback((index) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on('select', onSelect);
  }, [emblaApi, setScrollSnaps, onSelect]);

  return (
    <>
      <div className={`${className} block relative`}>
        <div className={`${className} overflow-x-hidden`} ref={emblaRef}>
          <div className="canvas flex">{children}</div>
        </div>
        <nav className="absolute bottom-0 right-0 lg:bottom-12 lg:right-12 z-10 flex justify-center lg:justify-end flex-nowrap">
          <ArrowL
            className="w-6 h-6 lg:w-12 lg:h-12 mr-2 lg:mr-4"
            onClick={() => emblaApi.scrollPrev()}
          />
          <ArrowR className="w-6 h-6 lg:w-12 lg:h-12" onClick={() => emblaApi.scrollNext()} />
        </nav>
      </div>
    </>
  );
};

export default StandardCarousel;
