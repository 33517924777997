import React from "react"

import { BlockWrapper } from "../blockWrapper"

import { BasicCarousel } from '../../carousels';

import AnchorScroll from 'react-anchor-link-smooth-scroll';

const AnchorLinks = ({ blockConfig, items }) => { 

  return (
    <BlockWrapper
      {...blockConfig}
      className={`bg-tint px-gutter overflow-x-hidden py-8 font-display text-brand tracking-wide text-xs md:text-base`}
    >
      <BasicCarousel>        
        {items.map((item, index) => {
          return (
            <div className="w-auto pr-10 flex-slide-item">
              <AnchorScroll href={`#${item.anchor.current}`}>{item.linkText}</AnchorScroll>
            </div>
          );
        })}
      </BasicCarousel>
    </BlockWrapper>
  );
};

export default AnchorLinks;
