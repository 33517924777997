import React from 'react'

import { BlockWrapper } from '../blockWrapper';

import { BasicCarousel } from '../../carousels';

const Timeline = ({ items, blockConfig }) => {
  return (
    <BlockWrapper className="px-gutter overflow-x-hidden cursor-drag" {...blockConfig}>
      <BasicCarousel>
        {items.map((item, index) => {
          const { year, caption } = item;
          return (
            <div className="w-full flex-slide-item md:w-1/2 lg:w-1/3">
              <div className="w-full flex justify-between relative mb-12">
                <div className="w-6 h-6 rounded-full bg-brand"></div>
                {index + 1 === items.length && (
                  <div className="w-6 h-6 rounded-full bg-brand"></div>
                )}
                <div className="absolute bg-brand h-0.5 left-2 right-0 top-1/2 transform -translate-y-1/2"></div>
              </div>
              <h3 className="text-3xl md:text-4xl lg:text-5xl text-brand font-bold mb-6">{year}</h3>
              <p className="pr-12 lg:pr-20 xl:pr-28 2xl:pr-32">{caption}</p>
            </div>
          );
        })}
      </BasicCarousel>
    </BlockWrapper>
  );
};

export default Timeline
