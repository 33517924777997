import React from 'react'
import Image from 'gatsby-plugin-sanity-image';

import { BlockWrapper } from '../blockWrapper';

import { BasicCarousel } from '../../carousels';
import { ProjectGridItem } from '../../ui';

const GridLayout = ({ items }) => {
  return (
    <div className=" grid grid-cols-1 grid-flow-row gap-12 lg:gap-28 xl:gap-36">
      {items.map((item, index) => {
        return (
          <>
            <ProjectGridItem index={index} {...item} />
          </>
        );
      })}
    </div>
  );
}

const SliderLayout = ({ items }) => (
  <BasicCarousel className="cursor-drag">
    {items.map((item, index) => {
      return (
        <div
          className={`w-full pr-8 flex-slide-item md:w1/2 lg:w-1/3 flex flex-col items-center gap-y-4`}
        >
          <div className="relative w-full aspect-w-3 aspect-h-2">
            <Image className="absolute inset-0 object-cover" {...item.image} />
          </div>
          <div className="w-full font-display text-brand">
            <p className="text-md lg:text-lg">
              {item.category}
              <span className="mx-2">-</span>
              {item.location}
            </p>
            <h4 className="text-lg lg:text-2xl mt-2">{item.title}</h4>
            <p className="hidden border-collapseprose xl:prose-xl font-body leading-normal text-gray">
              {item.caption}
            </p>
          </div>
        </div>
      );
    })}
  </BasicCarousel>
);

const ProjectGrid = ({ blockConfig, items, layout }) => {
  return (
    <BlockWrapper {...blockConfig}  className={`px-gutter overflow-x-hidden`}>
      {layout === 'featured' ? (
        <GridLayout items={items}/>
      ) : (
        <SliderLayout items={items}/>
      )}
    </BlockWrapper>
  )  
}

export default ProjectGrid
