import React from "react"

import { BlockWrapper } from "../blockWrapper"
import { PortableTextBlock } from "../../sanity/portableTextBlock"

const ContactDetails = ({ blockConfig, items }) => {  

  return (
    <BlockWrapper {...blockConfig} className={`px-gutter`}>      
      {items.map((item, index) => {
        const { heading, info } = item;
        return (
          <div className="w-full lg:grid lg:grid-cols-12 text-brand mb-12 lg:mb-20 xl:mb-28">
            <h5 className="font-display lg:text-lg lg:col-start-1 lg:col-end-6">
              {heading && heading}
            </h5>
            {info && (
              <PortableTextBlock
                className="lg:col-start-6 lg:col-end-13 lg:prose-lg xl:prose-2xl"
                text={info}
              />
            )}
          </div>
        );
      })}      
    </BlockWrapper>
  );
};

export default ContactDetails
