import React from "react"
import Image from "gatsby-plugin-sanity-image"

import { BlockWrapper } from "../blockWrapper"
import { PortableTextBlock } from "../../sanity/portableTextBlock"

const ImageText = ({ _rawText, image, layout, blockConfig }) => {
  let textCol = ""
  let imageCol = ''

  if (layout === "left") {
    imageCol = "md:col-end-8"
    textCol = 'md:col-start-9';
  } else if (layout === "right") {
    imageCol = 'md:col-start-8';
    textCol = 'md:col-end-7 row-start-1';
  }
    return (
      <BlockWrapper className="grid grid-cols-14 gap-y-8" {...blockConfig}>
        {image && (
          <Image
            className={`col-start-2 col-end-14 lg:self-center ${imageCol} w-full h-auto`}
            {...image}
          />
        )}
        <div className={`col-start-2 col-end-14 self-center ${textCol}`}>
          <PortableTextBlock className="prose xl:prose-lg max-w-none" text={_rawText} />
        </div>
      </BlockWrapper>
    );
}

export default ImageText
