import React from "react"

import { BlockWrapper } from "../blockWrapper"
import { PortableTextBlock } from "../../sanity/portableTextBlock"

const TextBlock = ({ _rawText, centerText, blockConfig }) => {
  return (
    <BlockWrapper className={`px-gutter`} {...blockConfig}>
      <PortableTextBlock
        className={`prose xl:prose-lg ${
          centerText ? 'text-center max-w-4xl mx-auto' : 'max-w-textBlock'
        }`}
        text={_rawText}
      />
    </BlockWrapper>
  );
}

export default TextBlock
