import React, { useState, useEffect, useCallback } from "react"
import { useEmblaCarousel } from "embla-carousel/react"
import { setupWheelGestures } from "embla-carousel-wheel-gestures"


const BasicCarousel = ({ children, className }) => {
  const [scrollProgress, setScrollProgress] = useState(0);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    loop: false,
    containScroll: 'trimSnaps',
    dragFree: false,
  });

  const onScroll = useCallback(() => {
    if (!emblaApi) return;
    setScrollProgress(emblaApi.scrollProgress() * 100);
  }, [emblaApi]);

  useEffect(() => {
    if (emblaApi) {
      // Embla API is ready
      emblaApi.on('scroll', onScroll);
      setupWheelGestures(emblaApi);
    }
  }, [emblaApi, onScroll]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollTo = useCallback((index) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on('select', onSelect);
  }, [emblaApi, setScrollSnaps, onSelect]);

  return (
    <>
      <div className={`${className} block relative`}>
        <div className={`${className} overflow-x-visible`} ref={emblaRef}>
          <div className="canvas flex">{children}</div>
        </div>        
      </div>
    </>
  )
};

export default BasicCarousel;
