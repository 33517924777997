import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

// Hooks
import { usePadTop, usePadBottom } from '../../hooks';

const aniVars = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay:0.3,
    },
  },
};

export const BlockWrapper = ({ children, className, paddingTop, paddingBottom, slug }) => {
  const padTop = usePadTop(paddingTop);
  const padBottom = usePadBottom(paddingBottom);

  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.01, triggerOnce: true });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.section
      className={`${className} ${padTop} ${padBottom}`}
      id={slug && slug.current}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
      variants={aniVars}
    >
      <div ref={ref} />
      {children}
    </motion.section>
  );
};
