import React from 'react';
import Image from 'gatsby-plugin-sanity-image';
import { motion } from 'framer-motion';

const LogoItem = ({ image }) => {


  return (
    <article className="w-32 md:w-44 lg:w-80 mx-auto self-center">
      <Image style={{width:'60%'}} className="mx-auto" {...image} />
    </article>
  );
};
const LogoCarousel = ({ blockConfig, heading, items }) => {


  return (
    <div
      {...blockConfig}
      className="grid grid-cols-1 grid-flow-row gap-y-8 lg:gap-y-16 mb-20 lg:mb-40 text-center pointer-events-none"
    >
      {heading && (
        <h4 className="text-2xl lg:text-4xl font-display text-brand col-span-1 px-gutter">
          {heading}
        </h4>
      )}
      <div className="col-span-1 w-full overflow-hidden h-32 relative">
        {/* Carousel One */}
        <motion.div
          className="absolute top-0 flex"
          animate={{ x: ['0%', '-100%'] }}
          transition={{ loop: Infinity, ease: 'linear', duration: 30, times: [0, 1] }}
        >
          {items.map((item) => (
            <LogoItem {...item} />
          ))}
        </motion.div>
        {/* Carousel Two */}
        <motion.div
          className="absolute top-0 flex"
          animate={{ x: ['100%', '0%'] }}
          transition={{
            ease: 'linear',
            duration: 30,
            loop: Infinity,
            times: [0, 1],
          }}
        >
          {items.map((item) => (
            <LogoItem {...item} />
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default LogoCarousel;
